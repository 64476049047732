<template>
  <div>
    <div class="login">
      <div class="loginMain">
        <h4>账号密码登录</h4>
        <div class="input">
          <p>用户名</p>
          <van-field v-model="phone" center clearable placeholder="请输入手机号">
          </van-field>
        </div>
        <div class="input">
          <p>验证码</p>
          <van-field v-model="sms" center placeholder="请输入验证码">
            <template #button>
              <div
                class="code-btn"
                @click="sendSms"
                :class="smsNum == 60 ? '' : 'no_touch'"
              >
                {{ smsNum == 60 ? smsText : smsNum + "s后重试" }}
              </div>
            </template>
          </van-field>
        </div>
        <el-button type="primary" class="btn" @click="toLogin">登录</el-button>
      </div>
    </div>
  </div>
  
</template>

<script>
document.ondragstart = function () {
  return false;
};
export default {
  name: "index",
  data() {
    return {
      isPassword: true,
      isLogin: false,
      phone:'',
      password:'',

      truePhone: '18331351977',
      truePassword: '26059',

      sms: "",
      smsTimer: "",
      smsNum: 60,
      smsText: "获取验证码",
    };
  },
  async mounted() {
  },
  computed: {
    
  },
  methods: {
    isPhoneNo(phone) {
      var pattern = /^1[3456789]\d{9}$/;
      return pattern.test(phone);
    },
    clearTimer() {
      if (this.smsTimer) {
        clearInterval(this.smsTimer);
        this.smsNum = 60;
      }
    },
    sendSms() {
      if (!this.phone) {
        this.$toast("请输入手机号");
        return;
      }
      if (!this.isPhoneNo(this.phone)) {
        this.$toast("手机号格式错误");
        return;
      }
      if (this.smsNum < 60) return;
      if (this.smsLoading) {
        return;
      }
      this.smsLoading = true;
      this.$api({
        url: "/api/auth/sms",
        method: "POST",
        data: {
          mobile: this.phone,
        },
      })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.$toast(res.msg);
            this.smsNum = this.smsNum - 1;
            this.smsTimer = setInterval(() => {
              this.smsNum = this.smsNum - 1;
              if (this.smsNum == 0) {
                this.smsNum = 60;
                this.clearTimer();
              }
            }, 1000);
          } else {
            this.$toast(res.msg);
          }
          this.smsLoading = false;
        })
        .catch((err) => {
          this.smsLoading = false;
          console.log(err);
        });
    },
    toLogin(){
      if (!this.phone) {
        this.$toast("请输入手机号");
        return;
      }
      if (!this.sms) {
        this.$toast("请输入验证码");
        return;
      }
    
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$api({
        url: "/api/auth/login",
        method: "GET",
        data: {
          mobile: this.phone,
          code: this.sms,
          channel: 8,
        },
      })
        .then((res) => {
          console.log(res);
          this.loading.close();
          if (res.code == 0) {
            this.$toast(res.msg);
            this.clearTimer();
            let { id, mobile, nickname, userface, access_token } = res.data;
            this.user = {
              id,
              mobile,
              nickname,
              userface,
              access_token,
            };
            localStorage.setItem("user", JSON.stringify(this.user));
            this.$store.commit("SET_USER", this.user);
            // this.setCookie('user', JSON.stringify(this.user))
            this.$store.commit("changeLoginShow", false);
            this.$store.commit("SET_LOGINSTATUS", true);
            this.$store.dispatch("getStudy");
            this.$store.dispatch("getVIPInfo");
            window.sessionStorage.setItem('zhijiaoLogin', this.phone) 
            location.reload()
          } else {
            this.$toast(res.msg);
          }
        })
        .catch((err) => {
          this.loading.close();
          console.log(err);
        });
    }
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    this.getData();
    next();
  },
};
</script>
<style lang="scss">
::-webkit-scrollbar{
  width: 8px;
  height: 7px;
  border-radius: 5px;
  background-color:#F4F4F4;
  border-bottom: 1px solid #ECECEC;
  border-top: 1px solid #ECECEC
}
::-webkit-scrollbar-thumb{
 border-radius:5px;
  background-color: #DEDEE4;
  border-radius: 5px;
  margin: 5px 0;
}
body{
  background: #fafafa;
}
</style>
<style lang="scss" scoped>
.login{
  background: url("../assets/images/zhijiao/loginBg.png") top center no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .loginMain{
    width: 462px;
    background: rgba(255,255,255,0.8);
    border-radius: 8px;
    border: 2px solid #FFFFFF;
    padding: 0 44px;
    .code-btn{
      cursor: pointer;
    }
    h4{
      font-weight: 500;
      font-size: 18px;
      color: #262937;
      line-height: 25px;
      text-align: center;
      display: block;
      margin: 40px auto;
    }
    .input{
      margin-bottom: 24px;
      p{
        margin-bottom: 12px;
        font-size: 14px;
        color: #262937;
        line-height: 20px;
      }
    }
    .btn{
      width: 100%;
      margin-top: 32px;
      margin-bottom: 56px;
    }
  }
}
</style>
